<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Itens de cobrança</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Itens de
                  <span>cobrança</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <section class="container mt-4">          
          <!-- Conteúdo principal -->
            <b-tabs>
              <!-- Itens -->
              <b-tab active @click="alteraFastNavegacao('exibirItens')">
                <template #title>
                  Itens de cobrança
                </template>
                <div class="row">

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.exibirItens" class="col-12">
                      <div class="card mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="alteraFastNavegacao('novoItem')"
                              >
                                <small>+ Novo item de cobrança</small>
                              </button>
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Item de cobrança</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Valor padrão</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Tipo</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Categoria</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastItensCobranca.loading">
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="text-center"
                                    >
                                    Carregando...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastItensCobranca.total.length">
                                  <tr
                                    v-for="e in fastItensCobranca.total"
                                    :key="e.id_item_cobranca"
                                  >
                                    <td class="align-middle text-center">
                                      {{ e.nome_item }}
                                    </td>   
                                    <td class="align-middle text-center">
                                      {{ formataPreco(e.valor_item) }}
                                    </td>  
                                    <td class="align-middle text-center">
                                      <span v-if="e.id_tipo_requerimento">
                                        Requerimento
                                      </span>
                                      <span v-else-if="e.id_curso">
                                        Curso livre
                                      </span>
                                      <span v-else>
                                        Produto
                                      </span>
                                    </td>       
                                    <td class="align-middle text-center">
                                      <span v-if="e.nome_categoria">
                                        {{e.nome_categoria}}
                                      </span>
                                      <span v-else>
                                        Nenhuma
                                      </span>
                                    </td>                        
                                    <td class="text-center align-middle">                                  
                                      <button
                                        v-if="!e.id_tipo_requerimento && !e.id_curso"
                                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                        @click="exibeModalEditarItem(e)"
                                      >
                                        <small>Editar</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                                        disabled
                                      >
                                        <small>Editar</small>
                                      </button>

                                      <button
                                        v-if="!e.id_tipo_requerimento && !e.id_curso"
                                        class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                        @click="exibeModalExcluirItem(e)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                                        disabled
                                      >
                                        <small>Excluir</small>
                                      </button>

                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="text-center"
                                    >
                                      Nenhum item cadastrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.novoItem" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Novo item
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome do item</label>
                                  <input v-model="fastItemCobrancaNovo.nome_item" :class="fastItemCobrancaNovo.nome_item ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <label>Valor do item</label>
                                  <money v-model="fastItemCobrancaNovo.valor_item" :class="fastItemCobrancaNovo.valor_item ? 'form-control text-center' : 'form-control text-center border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Categoria</label>
                                  <select class="form-control" v-model="fastItemCobrancaNovo.id_categoria_item">
                                    <option value="0">-- Nenhuma --</option>
                                    <option v-for="c in fastCategorias.total" :key="c.id_categoria_item" :value="c.id_categoria_item">
                                      {{c.nome_categoria}}
                                    </option>
                                  </select>                                  
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button class="btn btn-sm btn-primary mr-2" @click="criaNovoItemCobranca()">
                                    Criar novo item
                                  </button>
                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirItens')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.editarItem" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Editar item
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome do item</label>
                                  <input v-model="fastItemCobrancaEditar.nome_item" :class="fastItemCobrancaEditar.nome_item ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3">
                                  <label>Valor do item</label>
                                  <money v-model="fastItemCobrancaEditar.valor_item" :class="fastItemCobrancaEditar.valor_item ? 'form-control text-center' : 'form-control text-center border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Categoria</label>
                                  <select class="form-control" v-model="fastItemCobrancaEditar.id_categoria_item">
                                    <option value="0">-- Nenhuma --</option>
                                    <option v-for="c in fastCategorias.total" :key="c.id_categoria_item" :value="c.id_categoria_item">
                                      {{c.nome_categoria}}
                                    </option>
                                  </select>                                  
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button class="btn btn-sm btn-primary mr-2" @click="atualizaItemCobranca()">
                                    Salvar alterações
                                  </button>
                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirItens')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                </div>
              </b-tab>
              <!-- /Itens -->
              <!-- Categorias -->
              <b-tab @click="alteraFastNavegacao('exibirCategorias')">
                <template #title>
                  Categorias
                </template>
                <div class="row">
                  
                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.exibirCategorias" class="col-12">
                      <div class="card mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="alteraFastNavegacao('novaCategoria')"
                              >
                                <small>+ Nova categoria</small>
                              </button>
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Categoria</small>
                                    </th>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Itens</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastCategorias.loading">
                                  <tr>
                                    <td
                                      colspan="3"
                                      class="text-center"
                                    >
                                    Carregando...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastCategorias.total.length">
                                  <tr
                                    v-for="e in fastCategorias.total"
                                    :key="e.id_categoria_item"
                                  >
                                    <td class="align-middle text-center">
                                      {{ e.nome_categoria }}
                                    </td>   
                                    <td class="align-middle text-center">
                                      {{e.itens}}
                                    </td>                            
                                    <td class="text-center align-middle">                                  
                                      <button
                                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                        @click="exibeModalEditarCategoria(e)"
                                      >
                                        <small>Editar</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                        @click="exibeModalExcluirCategoria(e)"
                                      >
                                        <small>Excluir</small>
                                      </button>

                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="3"
                                      class="text-center"
                                    >
                                      Nenhuma categoria cadastrada
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.novaCategoria" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Nova categoria
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome da categoria</label>
                                  <input v-model="fastCategoriaNovo.nome_categoria" :class="fastCategoriaNovo.nome_categoria ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button class="btn btn-sm btn-primary mr-2" @click="criaNovaCategoria()">
                                    Criar nova categoria
                                  </button>
                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirCategorias')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>            
                    </div>
                  </transition>

                  <transition name="slide-fade">
                    <div v-if="fastNavegacao.editarCategoria" class="col-12">
                      <div class="card mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Editar categoria
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <label>Nome da categoria</label>
                                  <input v-model="fastCategoriaEditar.nome_categoria" :class="fastCategoriaEditar.nome_categoria ? 'form-control' : 'form-control border border-danger'"/>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                  <button class="btn btn-sm btn-primary mr-2" @click="atualizaCategoria()">
                                    Salvar alterações
                                  </button>
                                  <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirCategorias')">
                                    Voltar
                                  </button>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </transition>

                </div>
              </b-tab>
              <!-- /Categorias -->
            </b-tabs>
            

          
          <!-- /Conteúdo principal -->
        </section>
        
        <div
          class="container"
        >
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' +
                  $route.params.id_plataforma +
                  '/tesouraria'
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar para o menu
            </a>
          </div>
        </div>

      </div>
    </div>
    <!-- modal -->
    
    <modal
      name="modalExcluirItem"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir item?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirItem')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-danger">O item {{fastItemCobrancaEditar.nome_item}} será excluído permanentemente.</p>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirItem()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirCategoria"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir categoria?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirCategoria')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-danger">A categoria {{fastCategoriaEditar.nome_categoria}} será excluída permanentemente.</p>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirCategoria()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoTesourariaItensCobranca",
  components: {
    
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastNavegacao: {
        exibirItens: true,
        novoItem: false,
        editaItem: false,
        exibirCategorias: true,
        novaCategoria: false,
        editaCategoria: false
      },
      // Itens de cobrança
      fastItensCobranca: {
        total: [],
        loading: true
      },
      fastItemCobrancaNovo: {
        id_item_cobranca: 0,
        nome_item: "",
        valor_item: 0.00,
        quantidade: 1,
        id_plataforma: this.$route.params.id_plataforma,
        id_categoria_item: 0
      },
      fastItemCobrancaEditar: {
        id_item_cobranca: 0,
        nome_item: "",
        valor_item: 0.00,
        quantidade: 1,
        id_plataforma: this.$route.params.id_plataforma,
        id_categoria_item: 0
      },
      // Categorias
      fastCategorias: {
        total: [],
        loading: true
      },
      fastCategoriaNovo: {
        id_categoria_item: 0,
        id_categoria_item_pai: 0,
        nome_categoria: "",
        id_plataforma: this.$route.params.id_plataforma
      },
      fastCategoriaEditar: {
        id_categoria_item: 0,
        id_categoria_item_pai: 0,
        nome_categoria: "",
        id_plataforma: this.$route.params.id_plataforma
      },
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getItensCobranca(this.$route.params.id_plataforma)
            this.getCategorias(this.$route.params.id_plataforma)
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Itens de cobrança
    async getItensCobranca(id_plataforma){      
      this.fastItensCobranca.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_itens_cobranca/lista", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getItensCobranca", res);
        if (res.length) this.fastItensCobranca.total = res;
        else this.fastItensCobranca.total = []

        this.fastItensCobranca.loading = false
      }).catch(e => {
        console.log(e);
        this.fastItensCobranca.loading = false
      })
    },
    async criaNovoItemCobranca(){
      let erros = []
      this.fastItemCobrancaNovo.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastItemCobrancaNovo.nome_item) erros.push("O nome do item é obrigatório")
      if (!this.fastItemCobrancaNovo.valor_item) erros.push("O valor do item é obrigatório")
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.promisePostFastApi(this.fastItemCobrancaNovo, "api/fast_tesouraria_itens_cobranca/insere").then((res) => {
          console.log("criaNovoItemCobranca", res)
          if (res.length) {
            this.fastItemCobrancaNovo = {
              id_item_cobranca: 0,
              nome_item: "",
              valor_item: 0.00,
              quantidade: 1,
              id_plataforma: this.fastItemCobrancaNovo.id_plataforma
            }
            this.fastItensCobranca.total.push(res[0]);
            this.alteraFastNavegacao('exibirItens')
            this.exibeToasty("Item criado", "success")
          } else {
            this.exibeToasty("Erro ao criar item de cobrança", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar item de cobrança", "error");
        });
      }      
    },
    exibeModalEditarItem(item) {
      if (!item.id_categoria_item) item.id_categoria_item = 0
      this.fastItemCobrancaEditar = item
      this.alteraFastNavegacao('editarItem')
    },
    atualizaItemCobranca(){
      let erros = []
      if (!this.fastItemCobrancaEditar.nome_item) erros.push("O nome do item é obrigatório")
      if (!this.fastItemCobrancaEditar.valor_item) erros.push("O valor do item é obrigatório")
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.promisePostFastApi(this.fastItemCobrancaEditar, "api/fast_tesouraria_itens_cobranca/atualiza").then((res) => {
          if (res.length) {
            this.fastItemCobrancaEditar = {
              id_item_cobranca: 0,
              nome_item: "",
              valor_item: 0.00,
              quantidade: 1,
              id_plataforma: this.fastItemCobrancaNovo.id_plataforma
            }
            this.fastItensCobranca.total = res
            this.alteraFastNavegacao('exibirItens')
            this.exibeToasty("Alteração realizada", "success")
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao salvar alterações", "error");
        });
      }    
    },
    exibeModalExcluirItem(item) {
      this.fastItemCobrancaEditar = item
      this.showModal('modalExcluirItem')
    },
    excluirItem() {
      this.promisePostFastApi(this.fastItemCobrancaEditar, "api/fast_tesouraria_itens_cobranca/exclui").then((res) => {
        if (res.length) {          
          if (this.fastItensCobranca.total.length > res.length) {
            this.fastItensCobranca.total = res
            this.exibeToasty("Alteração realizada", "success")
            this.fastItemCobrancaEditar = {
              id_item_cobranca: 0,
              nome_item: "",
              valor_item: 0.00,
              quantidade: 1,
              id_plataforma: this.fastItemCobrancaNovo.id_plataforma
            }
            this.hideModal('modalExcluirItem')
          } else {
            this.exibeToasty("Esse item não pode ser excluir", "error")
          }
          
        } else {
          this.exibeToasty("Erro ao excluir item", "error")
        }         
      }).catch((e) => {
        this.exibeToasty("Erro ao excluir item", "error")
      });
    },
    // Categoria
    async getCategorias(id_plataforma){      
      this.fastCategorias.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_categoria/lista", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getCategorias", res);
        if (res.length) this.fastCategorias.total = res;
        else this.fastCategorias.total = []

        this.fastCategorias.loading = false
      }).catch(e => {
        console.log(e);
        this.fastCategorias.loading = false
      })
    },
    async criaNovaCategoria(){
      let erros = []
      this.fastCategoriaNovo.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastCategoriaNovo.nome_categoria) erros.push("O nome da categoria é obrigatório")
      if (!this.fastCategoriaNovo.id_categoria_item_pai) this.fastCategoriaNovo.id_categoria_item_pai = 0
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.promisePostFastApi(this.fastCategoriaNovo, "api/fast_tesouraria_categoria/insere").then((res) => {
          console.log("criaNovoItemCobranca", res)
          if (res.length) {
            this.fastCategoriaNovo = {
              id_categoria_item: 0,
              id_categoria_item_pai: 0,
              nome_categoria: "",
              id_plataforma: this.$route.params.id_plataforma
            }
            this.fastCategorias.total.push(res[0]);
            this.alteraFastNavegacao('exibirCategorias')
            this.exibeToasty("Categoria criada", "success")
          } else {
            this.exibeToasty("Erro ao criar categoria", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar categoria", "error");
        });
      }      
    },
    exibeModalEditarCategoria(categoria) {
      this.fastCategoriaEditar = categoria
      this.alteraFastNavegacao('editarCategoria')
    },
    atualizaCategoria(){
      let erros = []
      if (!this.fastCategoriaEditar.nome_categoria) erros.push("O nome da categoria é obrigatório")
      if (!this.fastCategoriaEditar.id_categoria_item_pai) this.fastCategoriaNovo.id_categoria_item_pai = 0

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.promisePostFastApi(this.fastCategoriaEditar, "api/fast_tesouraria_categoria/atualiza").then((res) => {
          if (res.length) {
            this.fastCategoriaEditar = {
              id_categoria_item: 0,
              id_categoria_item_pai: 0,
              nome_categoria: "",
              id_plataforma: this.$route.params.id_plataforma
            }
            this.fastCategorias.total = res
            this.alteraFastNavegacao('exibirCategorias')
            this.exibeToasty("Alteração realizada", "success")
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao salvar alterações", "error");
        });
      }    
    },
    exibeModalExcluirCategoria(categoria) {
      this.fastCategoriaEditar = categoria
      this.showModal('modalExcluirCategoria')
    },
    excluirCategoria() {
      this.promisePostFastApi(this.fastCategoriaEditar, "api/fast_tesouraria_categoria/exclui").then((res) => {
        if (res.length) {          
          if (this.fastCategorias.total.length > res.length) {
            this.fastCategorias.total = res
            this.exibeToasty("Alteração realizada", "success")
            this.fastCategoriaEditar = {
              id_categoria_item: 0,
              id_categoria_item_pai: 0,
              nome_categoria: "",
              id_plataforma: this.$route.params.id_plataforma
            }
            this.hideModal('modalExcluirCategoria')
          } else {
            this.exibeToasty("Essa categoria não pode ser excluída", "error")
          }
          
        } else {
          this.exibeToasty("Erro ao excluir categoria", "error")
        }         
      }).catch((e) => {
        this.exibeToasty("Erro ao excluir categoria", "error")
      });
    },
  },
};
</script>

<style></style>
